@import 'katex/dist/katex.min.css';

html {
  scroll-behavior: smooth;
}

/* General container for the blog post */
.blog-post-container {
  @apply text-gray-800 leading-7;
}

.bg-grid-pattern {
  background-color: #f5f7fa; /* Subtle background color */
  background-image: linear-gradient(90deg, rgba(0, 0, 0, 0.05) 1px, transparent 1px),
                    linear-gradient(rgba(0, 0, 0, 0.05) 1px, transparent 1px);
  background-size: 40px 40px; /* Adjust the size of the grid */
}


/* Headings */
.blog-post-container h1 {
  @apply text-4xl font-extrabold text-gray-900 mb-6;
}

.blog-post-container h2 {
  @apply text-3xl font-bold text-gray-800 mt-8 mb-4;
}

.blog-post-container h3 {
  @apply text-2xl font-semibold text-gray-700 mt-6 mb-3;
}

.blog-post-container h4 {
  @apply text-xl font-semibold text-gray-700 mt-6 mb-2;
}

/* Paragraph */
.blog-post-container p {
  @apply text-base leading-relaxed text-gray-600 mb-6;
}

/* Blockquote */
.blog-post-container blockquote {
  @apply border-l-4 border-indigo-500 pl-4 italic text-gray-700 my-4;
}

/* Lists */
.blog-post-container ul {
  @apply list-disc list-inside my-4;
}

.blog-post-container ol {
  @apply list-decimal list-inside my-4;
}

.blog-post-container ul li, 
.blog-post-container ol li {
  @apply mb-2 text-base leading-relaxed text-gray-600;
}

/* Links */
.blog-post-container a {
  @apply text-indigo-600 font-semibold hover:underline;
}

/* Images */
.blog-post-container img {
  @apply w-full h-auto rounded-lg shadow-md my-6;
}

/* Code blocks */
.blog-post-container pre {
  @apply bg-gray-900 text-gray-100 p-4 rounded-lg overflow-x-auto my-6;
}

.blog-post-container code {
  @apply bg-gray-200 text-gray-900 px-2 py-1 rounded-md;
}

/* Tables */
.blog-post-container table {
  @apply w-full table-auto border-collapse my-6;
}

.blog-post-container th {
  @apply border border-gray-300 bg-gray-100 px-4 py-2 text-left font-semibold text-gray-700;
}

.blog-post-container td {
  @apply border border-gray-300 px-4 py-2 text-left text-gray-600;
}

/* Horizontal Rules */
.blog-post-container hr {
  @apply border-t border-gray-300 my-8;
}


/* body, html {
  margin: 0;
  padding: 0;
  height: 100%;
  overflow-x: hidden; 
} */

img {
  max-width: 100%;
  height: auto;
  width: 100%;
  height: 100%;
}
.ql-toolbar {
  position: sticky;
  top: 0;
  background-color: white; /* Adjust background color as needed */
  z-index: 1000; 
}



.test-container {
  /* border: 2px solid rgba(128, 128, 128, 0.35); */
  /* box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06); */
  font-family: sans-serif;
  height: 92vh;

  resize: both;
  width: 100%;
}

.editor-style{
height: '100%';
overflowY: 'fixed';
}

pre {
  /* max-width: 100%; */
  overflow-x: auto; /*Enable horizontal scrolling for long lines if necessary */
  white-space: pre-wrap; /* Preserve white-space and break lines */
  /* background-color: #e3dfdf; */
  border-radius: 5px;
  padding: 5px;
}
/* Add a class-based style for the nav-link */
.nav-link {
  text-decoration: none;
  color: white;
  margin-right: 20px;
  font-size: 16px;
  transition: color 0.3s ease;
}

/* Add hover styles for the nav-link */
.nav-link:hover {
  color: #cccabe; /* Change to the desired color on hover */
}

/* Add a class-based style for the add question link */
.add-question {
  text-decoration: none;
  color: white;
  margin-right: 20px;
  font-size: 16px;
  border: 1px solid white;
  padding: 8px 12px;
  border-radius: 4px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

/* Add hover styles for the add question link */
.add-question:hover {
  background-color: #98afd8; /* Change to the desired background color on hover */
  color: #000000; /* Change to the desired text color on hover */
}

.modal-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100vh;
  background-color: rgba(255, 255, 255, 0.8);
}

.modal {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.modal-buttons {
  margin-top: 20px;
}


/* Styling for the banner at the top */
.banner {
  background-color: #f0f0f0;
  color: #010101;
  text-align: center;
  padding: 20px;
  height: 20px;
}

/* Styling for the container holding the left and right panes */
/*  */

/* Styling for the left pane */
.left-pane {
  /* flex:0; */
  background-color: #ffffff;
  padding: 10px;
  overflow-y: auto; 
  flex:1;
  box-sizing: border-box;  
  /* height: 92vh; */
  /* height: 100%; */
}

/* Styling for the right pane */
.right-pane {
  flex:1;
  /*width: 50%;*/
  background-color: #ffffff;
  border: 1px solid rgb(202, 199, 199); 
  overflow-y: auto; /* Add scrolling if the content exceeds the fixed height */
}

/* Styling for the "editor-and-chat" container */
.editor-and-chat {
  display: flex;
  flex-direction: column; /* Stack AceEditor and ChatUI vertically */
  height: 100%; /* Make sure it takes up the full height of the parent container */
  
}

/* Styling for the AceEditor */
.ace-editor {
  flex: 1; /* Allow AceEditor to expand to fill available space */
}

/* Styling for the Chat UI Container */
.chat-ui {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  height: 100%; /* Allow it to take the full height of the parent */
  min-height: 100px; /* Example: Set a minimum height */
  overflow: auto; /* Allow for scrolling */
  /* background-color: #00000000; */
  /* border: 1px solid black;  */
  border-radius: 0px; 
  
}

/* Styling for the Chat Messages Area */
.chat-ui .messages {
  flex: 1; /* Allow this area to expand */
  overflow-y: auto;
  /* border: 1px solid black; Black border with 1px width */
  /* border-radius: 2px; */
  /* border: 1px solid rgb(195, 187, 187);  */
  min-height: 50px; /* Example: Set a minimum height for the messages */
}

.chat-ui .message {
  margin: 5px;
  padding: 1px;
  border-radius: 2px;
}

.chat-ui .user {
  align-self: flex-end; /* User messages align to the right */
  background-color: #ffffff;
  /* color: #fff; */
}

.chat-ui .ai {
  align-self: flex-start; /* Assistant messages align to the left */
  background-color: #ffffff;
}

/* Styling for the Input Area */

.ai-bubble {
  display: flex;
  flex-direction: column;  
  align-items: flex-start;  
}

.ai-icon {
  display: flex;
  align-items: center;  
  margin-bottom: 0px;  
}

.ai-text {
  /* margin-left: 8px; */ 
  font-weight: bold; 
  display: flex;
  align-items:center;
  margin-bottom: 0px;
  margin-top: 5px; 
}


.ai-message-content {
  margin-left: 5px; /* Adjust the margin as needed */
}

.ai-message-content-2 {
  margin-left: 0px; /* Adjust the margin as needed */
}

/* .ai-message-content-2 pre {
  padding: 10px;
  background-color: #2d2d2d;
  border-radius: 4px;
  font-family: Consolas, Monaco, 'Andale Mono', 'Ubuntu Mono', monospace;
  font-size: 14px;
  color: #f8f8f2;
  overflow-x: auto;
} */


.custom-html-content code {
  background-color: #f0f0f0;
  color: #000000;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}

.custom-html-content pre code {
  background-color: transparent;
  color: inherit;
  font-family: inherit;
  font-size: 13px;
  padding: 0;
  border-radius: 0;
  border: none;
}

.custom-html-content li {
  margin-bottom: 4px;          /* Slightly less spacing for nested list items */
}

.custom-html-content ul {
  list-style-type: disc;     /* Use a different bullet shape for nested lists */
  margin-bottom: 4px;          /* Slightly less spacing for nested list items */
}

.custom-html-content ol {
  list-style-type: decimal;     /* Use a different bullet shape for nested lists */
  margin-bottom: 4px;          /* Slightly less spacing for nested list items */
}

.ai-message-content-2 code {
  background-color: #f0f0f0;
  color: #000000;
  font-family: 'Courier New', Courier, monospace;
  font-size: 14px;
  padding: 2px 4px;
  border-radius: 4px;
  border: 1px solid #e1e1e1;
}

.ai-message-content-2 pre code {
  background-color: transparent;
  color: inherit;
  font-size: 13px;
  font-family: inherit;
  padding: 0;
  border-radius: 0;
  border: none;
}


.ai-text svg {
  margin-right: 5px; /* Adjust the margin as needed */
}
.ai-icon div {
  vertical-align: top; /* Aligns the content within the div to the top */
}

.ai-message {
  padding: 5px; 
}

.ai-message-2 {
  padding: 5px; 
  /*background-color: #e9ecf0;*/
}

.clear-conversation {
  text-align: center;
  margin-top: 10px; /* Adjust the margin as needed */
  cursor: pointer;
}

.clear-link {
  color: blue; /* Customize the link color */
}

.clear-link:hover {
  text-decoration: none; /* Remove underline on hover if desired */
}

.submit-and-language {
  flex-shrink: 0; 
  padding: 0px; /* Add padding as needed */
  /* background-color: #f8f8f8; Optional: add a background color */
  /* z-index: 1; Optional: bring the div to the front */
}

.delete-icon-class:hover {
  color: blue; /* Change the color as needed */
  /* or add other styling for a hover effect */
}



.delete-icon-class {
  cursor: pointer;
}

.small-icon {
  width: 18px;
  height: 18px;
}

.editor-container {
  flex-grow: 1; 
  overflow-y: hidden; 
  height: 100%;
}

.editor-container-text{
overflow: auto;
}

.topRightPaneStyle {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.user-message {
  background-color: #d3e8ff;
  border-radius: 5px;
  padding: 5px;
}

/* Styling for the LeetCode List */
.leetcode-list {
  list-style: none;
  padding: 0;
  margin: 0;
}

/* Styling for individual LeetCode questions */
.leetcode-item {
  background-color: #f0f0f0;
  padding: 15px;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Styling for LeetCode question title */
.leetcode-title {
  font-weight: bold;
  font-size: 18px;
}

/* Styling for LeetCode question difficulty */
.leetcode-difficulty {
  font-style: italic;
  color: #555;
}

/* Hover effect for individual LeetCode questions */
.leetcode-item:hover {
  background-color: #e0e0e0;
}
* {
  box-sizing: border-box;
}

/* Example CSS */
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #333;
  color: #fff;
}

.logo {
  font-size: 24px;
}

.nav-links {
  display: flex;
  gap: 20px;
}

.nav-links a {
  text-decoration: none;
  color: #fff;
}

.bc-links {
  display: flex;
  gap: 20px;
}

.bc-links a {
  text-decoration: none;
  color: #020202;
}
.auth-container {
  max-width: 300px;
  margin: 0 auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
}

.user-status {
  margin-bottom: 10px;
  font-weight: bold;
}

.auth-container input {
  width: 100%;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.auth-container button {
  width: 100%;
  padding: 10px;
  border: none;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
  background-color: #007bff;
  color: #fff;
}

.auth-container button:hover {
  background-color: #0056b3;
}


.list-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  margin-top: 20px; /* Add margin if needed */
}


.list-content {
  width: 60%;
  margin-top: 10px;
}



.header {
  height: rem(50px);
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  border-bottom: rem(1px) solid light-dark(var(--mantine-color-gray-3), var(--mantine-color-dark-4));
}

.table-container {
  width: 100%;
}

/* .link {
  color: blue;
  text-decoration: none;
} */

.link {
  display: flex;
  align-items: center;
  height: 100%;
  padding-left: var(--mantine-spacing-md);
  padding-right: var(--mantine-spacing-md);
  text-decoration: none;
  color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
  font-weight: 500;
  font-size: var(--mantine-font-size-sm);
  @mixin hover {
    background-color: light-dark(var(--mantine-color-gray-0), var(--mantine-color-dark-6));
  }

}

.link:hover {
  color: darkblue;
}

.custom-html-content p {
  margin: 0; /* Remove default margin */
  padding: 5px 0px; /* Add padding above and below */
  font-size: 15px; /* Set font size */
  line-height: 1.5; /* Set line height */
}

.custom-html-content strong {
  font-weight: bold; /* Set font weight */
  color: #000000; /* Set text color */
}

.custom-html-content li {
  margin-bottom: 8px; /* Add some space between list items */
  color: #000000; /* Set text color to black */
  font-size: 15px; 
  /* list-style-position: inside; Position the list item markers inside the content flow */
  /* text-indent: -20px; Indent the text within the list items */
}

.custom-html-content ul {
  padding-left: 20px; /* Add left padding to the unordered list */
  list-style-type: disc;
}
.custom-html-content ol {
  padding-left: 20px; /* Add left padding to the unordered list */
  list-style-type: decimal; /* Set list item bullet style to square */

}

/* .custom-html-content pre q {
  display: inline;
  margin: 0;
  padding: 0;
  line-height: 1;
  quotes: none;

} */

/* .logo {
  filter: invert(1) brightness(1.2);
}
 */

.custom-html-content pre {
  padding: 10px; /*Add padding */
  background-color: #f4f4f4; /* Set background color */
  /* border: 1px solid #cccccc; Add border */
  overflow-x: auto; /* Add horizontal scroll if needed */
  font-size: 14px; /* Set font size */
  line-height: 1.5; /* Set line height */
  white-space: pre; /* Preserve white space and wrap lines */
  margin-bottom: 5px;
  box-sizing: border-box;
  max-width: 100%;
}

.custom-html-content code {
  color: #000000;
  white-space: pre;
  font-size: 14px; 
  background-color: #f4f4f4; 
}

.custom-html-content h1 {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-html-content h2 {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-html-content h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000000;
}

.custom-html-content h4 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}






.custom-html-content-2 p {
  margin: 0; /* Remove default margin */
  padding: 0px 0px; /* Add padding above and below */
  font-size: 15px; /* Set font size */
  line-height: 1.5; /* Set line height */
}

.custom-html-content-2 strong {
  font-weight: bold; /* Set font weight */
  color: #000000; /* Set text color */
}

.custom-html-content-2 li {
  margin-bottom: 8px; /* Add some space between list items */
  color: #000000; /* Set text color to black */
  font-size: 15px; 
  /* list-style-position: inside; Position the list item markers inside the content flow */
  /* text-indent: -20px; Indent the text within the list items */
}

.custom-html-content-2 ul {
  padding-left: 20px; /* Add left padding to the unordered list */
  list-style-type: disc;
}
.custom-html-content-2 ol {
  padding-left: 20px; /* Add left padding to the unordered list */
  list-style-type: decimal;

}

/* .custom-html-content pre q {
  display: inline;
  margin: 0;
  padding: 0;
  line-height: 1;
  quotes: none;

} */

/* .logo {
  filter: invert(1) brightness(1.2);
}
 */

 .custom-html-content-2 pre {
  padding: 10px; /*Add padding */
  background-color: #f4f4f4; /* Set background color */
  /* border: 1px solid #cccccc; Add border */
  overflow-x: auto; /* Add horizontal scroll if needed */
  font-size: 14px; /* Set font size */
  line-height: 1.5; /* Set line height */
  white-space: pre; /* Preserve white space and wrap lines */
  margin-bottom: 5px;
  box-sizing: border-box;
  max-width: 100%;
}

.custom-html-content-2 code {
  color: #000000;
  white-space: pre;
  font-size: 14px; 
  background-color: #f4f4f4; 
}

.custom-html-content-2 h1 {
  font-size: 22px;
  font-weight: bold;
  color: #000000;

}

.custom-html-content-2 h2 {
  font-size: 20px;
  font-weight: bold;
  color: #000000;

}

.custom-html-content-2 h3 {
  font-size: 18px;

  font-weight: bold;
  color: #000000;
}

.custom-html-content-2 h4 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;

}


.ai-message-content,
.ai-message-content-2 {
  white-space: normal; /* Allows text to wrap within the available width */
  word-wrap: break-word; /* Breaks words if necessary to prevent overflow */
}

/* This applies only to pre tags to allow horizontal scrolling */
.ai-message-content pre,
.ai-message-content-2 pre {
  white-space: pre; /* Maintains whitespace and line breaks */
  overflow-x: auto; /* Enables horizontal scrolling if content exceeds width */
  max-width: 100%; /* Prevents the pre tag from exceeding the width of its container */
}

.ai-message-content-2  p {
  margin: 0; /* Remove default margin */
  padding: 5px 0px; /* Add padding above and below */
  font-size: 16px; /* Set font size */
  line-height: 1.5; /* Set line height */
}

.ai-message-content-2  strong {
  font-weight: bold; /* Set font weight */
  color: #000000; /* Set text color */
}

.ai-message-content-2  ul {
  padding-left: 20px; /* Add left padding to the unordered list */
  color: #000000;
  list-style-type: disc;

}
.ai-message-content-2 ol {
  padding-left: 20px; /* Add left padding to the unordered list */
  color: #000000;
  
  list-style-type: decimal;
}

.ai-message-content-2  li {
  margin-bottom: 8px; /* Add some space between list items */
  color: #000000; /* Set text color to black */
  /* list-style-position: inside; Position the list item markers inside the content flow */
  /* text-indent: -20px; Indent the text within the list items */
}








.ai-message-content-2  h1 {
  font-size: 22px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ai-message-content-2  h2 {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.ai-message-content-2  h3 {
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-weight: bold;
  color: #000000;
}

.ai-message-content-2  h4 {
  font-size: 16px;
  font-weight: bold;
  color: #000000;
  margin-top: 10px;
  margin-bottom: 10px;
}

.custom-quill .ql-container {
  border: none !important;
}

.custom-quill .ql-editor {
  border: none !important;
  font-size: 14px;
}

/* .custom-quill .ql-toolbar {
  border: none;
  border-bottom: 1px solid #f0f0f0; 
  z-index: 10; 
} */
.custom-quill-2 .ql-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
}

.custom-quill-2 .ql-toolbar {
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.custom-quill-2 .ql-editor {
  flex: 1;
  overflow: auto; /* or hidden, if you prefer */
}


/* Remove all borders from the toolbar, then add only a bottom border */
.custom-quill .ql-toolbar.ql-snow {
  border: none; /* Remove default borders */
  border-bottom: 1px solid #dadada; /* Add your desired bottom border */
  z-index: 10; 

}

.custom-quill-2 .ql-toolbar.ql-snow {
  border-bottom: 1px solid #dadada; /* Add your desired bottom border */
  z-index: 10; 

}
.video-container {
  position: relative;
  width: 100%;
  aspect-ratio: 16 / 9; /* adjust to your video's ratio */
}
.video-container video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}




.user-message {
  background-color: #f0f0f0; /* Light grey background */
  /* align-self: flex-end; Align to the right */
  margin-right: 10px; /* Optional: add some margin to the right */
  border-radius: 40px; /* Optional: add some border radius */
  padding: 10px; /* Optional: add some padding */
  /* max-width: 80%; Optional: limit the width of the message */
}
.code-highlight {
  float: left; /* 1 */
  min-width: 100%; /* 2 */
}

.code-line {
  display: block;
  padding-left: 16px;
  padding-right: 16px;
  margin-left: -16px;
  margin-right: -16px;
  border-left: 4px solid rgba(0, 0, 0, 0); /* Set placeholder for highlight accent border color to transparent */
}

.code-line.inserted {
  background-color: rgba(16, 185, 129, 0.2); /* Set inserted line (+) color */
}

.code-line.deleted {
  background-color: rgba(239, 68, 68, 0.2); /* Set deleted line (-) color */
}

.highlight-line {
  margin-left: -16px;
  margin-right: -16px;
  background-color: rgba(55, 65, 81, 0.5); /* Set highlight bg color */
  border-left: 4px solid rgb(59, 130, 246); /* Set highlight accent border color */
}

.line-number::before {
  display: inline-block;
  width: 1rem;
  text-align: right;
  margin-right: 16px;
  margin-left: -8px;
  color: rgb(156, 163, 175); /* Line number color */
  content: attr(line);
}

.video-container {
  position: relative;
  padding-bottom: 56.25%; 
  height: 0;
  /* overflow: hidden; */
  max-width: 100%;
  background: 'white';
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

/* styles.css */
body.dark-mode {
  background-color: #121212;
  color: #ffffff;
}

.dark-mode .bg-gray-100 {
  background-color: #1f1f1f;
}

.dark-mode .text-gray-700 {
  color: #e0e0e0;
}

.dark-mode .bg-white {
  background-color: #2e2e2e;
}

.ql-tooltip {
  right: 0 !important;
  left: auto !important;
  transform: none !important;
}







